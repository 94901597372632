const easy = [
	'016002400320009000040103000005000069009050300630000800000306010000400072004900680',
	'017030590000000403000006070120009050000402000060700021030600000901000000054020310',
	'018093005002710000046000000000000079100904002250000000000000920000032500600850140',
	'018700059700000000405800030607900000000050000000007106050001904000000003830002560',
	'019000000002390010357200000000002930200000008091600000000008293080024600000000850',
	'020056000000009018500000200000780059005000800270045000006000004430600000000570090',
	'020080004800140020059200300760000000000907000000000056002004910040031007600090030',
	'020089107001002300950000600400900000000010000000004003009000012002400500103520080',
	'020090407700302500000000006359000000040020060000000745900000000004601003806030070',
	'020100004010024090900300260402030000000205000000070509041007003080510070700002080',
	'020103000000006190000002704800000070530000016090000003403600000018300000000509080',
	'020300000090000580703000000100508020200090008050703009000000801081000030000009060',
	'020307000900000170701604005310500000200000006000002017800709400079000008000106030',
	'023100900100000080700059013071003400300000008002400370910520007030000004007001290',
	'024010090010000300003648000000780000270030041000029000000851700009000050080090410',
	'025000070000000901600410200000069000009104500000370000004053009206000000050000480',
	'025607100000005900700030000001200080090000010050009200000060002004900000008503670',
	'027900010000070000000000026308517000006040800000863405280000000000050000010004380',
	'028003140700000000014000005031264000000000000000978430100000290000000007049600350',
	'029004700580001000070056900000000007712000698400000000001620030000900046008500170',
	'030000000005010203060930400000100630306050108024003000009042060602080700000000080',
	'030000046200000075000400300053090000406805709000070150009006000640000008170000030',
	'030002900500000000000004378800230090600040003070089001325600000000000007007900060',
	'030020050600004002005000004083007900000851000001900840400000600100700008050010090',
	'030060824860014000900300000000049750000000000076250000000002007000470085795030040',
	'030504790756010000000000003470000000003060200000000071300000000000050824018709030',
	'030700920801050007000000085050200000070401060000005070510000000200080409089007010',
	'030897500005040100000001006000000279800000001719000000500600000001030900003189060',
	'031450000640000000005002001000001950500947003019300000300100500000000024000084730',
	'035290000000800350010030000009600830400050007082001900000060020021009000000025640',
	'036059000000000900009010076000007080907000104050800000460030500002000000000720840',
	'036090001800000000021003807058700000000935000000008650107200360000000009500080720',
	'040000000002800003001376020007008106409060208506900700060291300200004900000000040',
	'040000000100052600900403070000001039800020005390800000050208006008530002000000010',
	'040000103000300080000028500005004200210807096003200700009170000020003000506000070',
	'040001009000000054509400002102070000000803000000040207200006803360000000800500060',
	'040002510002056003907000000000000097003040200120000000000000301400860700091500080',
	'040006000000002040098045000034000058600000004870000620000130560080700000000200070',
	'040020000018005000070308400450000000000276000000000068005702010000400790000010050',
	'040100005000080000280306000028030079300000006760050430000507092000090000500003010',
	'040507000000000091009003480003040002001609300400010700037900800680000000000804030',
	'040690000000000000387001600500020470001040300064080005008400291000000000000068030',
	'040800300000004200067209140000000801080070050601000000073601490005700000006008010',
	'040900000002630507690000008000120000700000001000095000400000023506042100000001070',
	'040950160000000000000632900567200000090000070000005698002569000000000000086074050',
	'041000000008006740306000000070095010803167402010320070000000804035200900000000230',
	'041059000600000000800360000200004053006935200750200001000048007000000006000620410',
	'042000000900354000000000050706830000030040020000019807050000000000728009000000270',
	'042003950000000070000000301470300500050906010006001034708000000060000000035600290',
	'042600000600090030009100080060001000007000500000200070010003400090050006000008920',
	'047000061800200000006703800000060180600070009019050000003609500000005003480000690',
	'047200609008970040000000000005000030314060597090000200000000000080056300201008960',
	'048039000025000040900000003060001000002853100000600050600000001080000730000410520',
	'048390010000008000000001520009080006000905000800030900032400000000200000050073460',
	'048602050000000000005070832000000014437000528680000000872010900000000000010908370',
	'048690200701002000000000049000080320100000005056030000820000000000100508004058670',
	'048700050000300000000080609060007034080124060420600080803010000000008000090006720',
	'049000080000671000000900002500410200092000740007095003800004000000759000010000570',
	'050000000102400050630580000095000068400030001820000430000052017010009305000000090',
	'050000004230004600006000035500800700090010080008009002670000200001600058800000070',
	'050000080920750160000000790400270801000000000305089007069000000032065078040000030',
	'050000300900070084001800200185207000000060000000409138007002800690080002002000060',
	'050007906087003000000002000500190007740000095900078003000300000000800610801700040',
	'050008000691500008000000625780960000000000000000072094439000000500004937000300060',
	'050009487300000000080270300006000035100806002520000600005068040000000003693400070',
	'050013080400500006308000004210070000000000000000020093500000307700008001020750040',
	'050020004007580920400006000000000493060000050328000000000700006013042500500090010',
	'050040000900000032308005060000200008070304010600001000090700406580000003000050090',
	'050300800000409356000750009006000007000000000300000600700082000639504000005007010',
	'050400000000080659010306000627000000300000005000000294000209070275010000000007020',
	'050409760210003000004020000001005040005000600060800100000030800000200071097501020',
	'050430000479802600000000000003074200047000530001350700000000000005607912000029070',
	'050600741060000000308200000004300000630907082000005900000003504000000060481002070',
	'050800100000019600000000240685000000007308900000000862063000000008720000009001030',
	'051037000000010009706008100695400000000000000000005694008300701300050000000170530',
	'051080006006350400000006020810000200000508000004000057040100000007039500500040680',
	'052071003007500082060000000000004090004000500010300000000000060520007100900610870',
	'053106000680000000200000065004280016000060000720031900410000003000000024000902680',
	'056003700000006500000000029004070000729000135000050400810000000005400000003900210',
	'056070000900002000078564030420008050000000000080700042030486710000300008000090420',
	'057000002000050180000301700029106000000070000000504910004805000086010000500000630',
	'057420000630005000089310000000000400300748005004000000000067130000200094000034260',
	'058040030000087205300000087009006000000030000000400700210000003803210000040050920',
	'058300000000194700000006920490000017000000000830000054082900000003718000000002470',
	'058900060100007090203050108900000000006000800000000005309080406010600002040009380',
	'060000000000250060108000207009500070040793010080006500602000701090075000000000040',
	'060000000001009020000007143000250096036000210490068000843700000020800700000000030',
	'060000000004050068008930004050000630010040020026000040900085200640070900000000070',
	'060000000005026400020000098240093860000040000053860042370000050001570300000000070',
	'060000200070000386304068005000037000080090070000580000100850407258000090006000020',
	'060000504009100000014500790600002000000873000000900003097008640000004900103000080',
	'060040820500000000020008001070100004046203750900005060200800010000000008091060040',
	'060100000703000510108200000070040008901683402600020090000006801046000905000002040',
	'060180004400700020005300080300000700000060000001000003040009500080005002900031040',
	'060407810500002000000000923071000059000000000690000240136000000000200004027501080',
	'060510208000006039002000000000000014080401090450000000000000500270800000503079080',
	'060829000040060030007000080700030500001905600006070009070000100020010090000293040',
	'060830002030020000000000100620078001000514000400260053002000000000040090700085060',
	'061000000054082000830000000070400002205306907600005040000000095000260810000000260',
	'062300700004060900900000000308000062500020004290000103000000007001080600005009340',
	'062900010038452090500060000000000051200506004350000000000070008080295170070008520',
	'064002300003710000700003000340009007500000006900800043000900008000025700005400920',
	'068039000900060018100000000000500702810000064203004000000000006350020001000340820',
	'068120000007004000900800000070003200403080105006400080000009001000300400000061920',
	'069000000308500900200009000080390001006040700900027080000100004007006502000000160',
	'069030000000165900700000000096051030003649200040370590000000001007528000000010780',
	'069400000725030049403000000000006090030010020050900000000000406890040235000008910',
	'070000080005018006000000251200805600050060040003902007768000000500180400010000060',
	'070000300000347509800000000009604018000020000680103900000000003901482000006000020',
	'070009005000001000123008070600000100002486700007000002080300921000800000200100050',
	'070010209009480000006300007930000000004030800000000054600003400000062100802050060',
	'070018000000002807003500000240900070001287300030005062000009600609800000000430080',
	'070020900000007104000051060620008400000060000008500026090170000201300000004080030',
	'070400000290001400000900002006003009400789001500600300100005000005100063000008010',
	'070400003090100000003605008000000304084000720306000000200509400000003010100002090',
	'070500018000000004453700600000200060009604200060007000005009142100000000620001080',
	'070900004002000000050087060003000245800010007624000900060230010000000500200006090',
	'071439000000000000603075200000000061010596070540000000002960704000000000000742610',
	'071605830006090007020000004000570000008000200000064000500000010800050700063701540',
	'072003900430000000090470000005020000003849700000060400000032040000000092006100570',
	'072610000080300007100070006050000140620000078014000060400060003200008050000053420',
	'073900000012000000004850000009270000760493081000065900000089300000000470000007520',
	'076080000800000200091027000400002005050030060100400007000840730003000001000060890',
	'076180020200000507000007100800005000092000610000900008005800000904000006020054370',
	'076308090000001002004007030460200900000000000008006014030800100200100000080702640',
	'076430005300072086000000000010300200080060030002008060000000000960710002200083510',
	'079540000600000190000036800006073500300000007007460900002650000091000003000019450',
	'080000000000609502024300000000706210070090030039204000000003170103907000000000040',
	'080000000000946007060307000570000009804503702600000015000801030100469000000000050',
	'080000030020017000400235910600003000290040056000500001034652009000370040070000020',
	'080006300570090000201804907620000000000080000000000038809207106000060079005900020',
	'080030000004000120902600000020308000370000058000702060000009607016000400000080010',
	'080030057000564000000000200050000049004873600860000030003000000000928000790050020',
	'080030407200074180001000000060089000000000000000120070000000600025390008706010020',
	'080200530069050000001048000000000670200000009048000000000410900000070120097005060',
	'080409030032000080600800000000000063006758100970000000000001004050000670010604050',
	'080750340700009600000000007001080093970000026240090100300000000002300008064015030',
	'081007040000000060907500000005004608000706000702800900000003402030000000070400530',
	'083001209910008070500009000008000060000050000070000900000800006040600013109300840',
	'084060009000000300000037016150000003008106500400000061210580000003000000500010230',
	'084500000001002650000060090000000567060401030235000000050080000017900300000007420',
	'086000094400090000000000508090038100070609030001740050703000000000070005910000240',
	'087000069000070000000104700030208057700030001960701030001803000000090000590000370',
	'089000000000065000047900621065001000000609000000300850231008560000520000000000940',
	'089571400000000000073004020000006005305000802800900000010400360000000000006753980',
	'089700100010000000460200000530120000800050002000067095000008064000000070004001230',
	'090000000040300000080690257006070100210080075008030900853027090000003020000000080',
	'090000000740005000000871050500302079800000005930506001080713000000600087000000060',
	'090000146170000000050400020000024300002080900007590000080006010000000038526000070',
	'090000726300050400600009100000680000000705000000012000009100007002090003748000060',
	'090006370000817500050000000041608093000000000380704610000000050002975000065100030',
	'090028003000007400030100000000000280658000739017000000000006050009300000300750040',
	'090046500800019630000800000000100790100000005029004000000001000051490002002530010',
	'090050800382010000401000000024038100030090020009640580000000908000060475005070060',
	'090082100782600400000000006000000365049203780863000000400000000008009674005340010',
	'090176000000400900000020074520610400800000002006058037150060000002003000000791060',
	'090200100056400038000006007361000000000000000000000743200900000410003670007005090',
	'090250400301060000000001590000000803000579000605000000089600000000090305003048070',
	'090300100000750608004100000000000720400000001058000000000006400802094000009003050',
	'091007000000060800030205060573000900000000000006000327040501080009070000000300570',
	'092000003570800000000200040010080400208904701005010020060001000000008092700000650',
	'092450300008020107000100000264000000000070000000000568000008000905060400003019850',
	'093050000000407801408600050200070000800105006000020005040009602902704000000060790',
	'096040000000803000000100423503000000089000510000000604872001000000308000000090860',
	'096150803800004005014000000000000002005783400700000000000000760100600004607042580',
	'097000000000000630000052810380009000060030080000600041024960000018000000000000270',
	'097000230000740081010030000009000504000090000205000100000010040620059000051000390',
	'097028000000000060000500210003006490050943080019800700035007000080000000000430820',
	'098003000003004000010502060000038002800000005300650000070406080000300100000100290',
	'100000000000014275000930000005000009039157420700000800000041000968520000000000003',
	'100000000020013950080069007090500000037000620000006090600970080015320060000000009',
	'100000000070000800000850069700003980000469000032100004620017000009000030000000006',
	'100000040740003001800007602000004380000789000028300000203900004600200018010000003',
	'100000048000910000760200000000060427003000500874090000000005091000031000430000005',
	'100000070574100000008005400700030100940502037006010002003800700000009361050000008',
	'100000095096300704000000000010062000802000307000480010000000000704005620980000001',
	'100000400000200056206700000000120800020080070009057000000006708430008000005000003',
	'100000500007240003806950014700530000000000000000026005650078401900015800001000007',
	'100000860000000070000632015790800040030040080020001053970316000010000000085000001',
	'100003700020008000007090624002700006050109070700004900275080100000900040001300002',
	'100004950000000000020509610030600000000475000000003080051806090000000000064900007',
	'100005869000300000005010320000000008480609072300000000023080900000007000869400001',
	'100030000090010827005000000000003005703020409200400000000000500821090070000060003',
	'100030209008005000409000000600078300307000506002650004000000802000200100901080003',
	'100060000030470000040008306405007000670000085000600401703500040000046050000090003',
	'100060200096720000430500000001905006000000000200106400000001064000058790004090002',
	'100078000000000502093100000000000320306000907082000000000005490201000000000630008',
	'100083906082090040000000000000001003940368052300900000000000000090050260401270005',
	'100085000004000080302400000007000160010529030035000200000002504020000700000190006',
	'100090007080600000000400698060000305050000040307000060294005000000003080500040006',
	'100204800030800170400000009900007000000631000000400008600000007041008050005306002',
	'100208070048930060790000000900000000005020800000000007000000016080075420020406005',
	'100300070000000000500070389012004000860050024000800730485020007000000000020009005',
	'100400009004050600000260004200600010900000007030007002500043000003070100400002003',
	'100508047004070000000004000702800604300090005609002308000400000000020100870109003',
	'100603200000000680009200500030000006200080001800000040002005900095000000006307002',
	'100800000000010030850000900007036009082070450600480100004000087030090000000003001',
	'100800000007930100020071800090067008700389002800420070008250090006093400000008003',
	'100900000004700025080000640000150003800000004600032000095000070710004500000007006',
	'100900502000500030000104000010000647006807100974000050000309000060002000809006005',
	'102000000000080200043500100000350004075208690800064000004005980001070000000000301',
	'102000070600029000000410500000000240070506030031000000003048000000150006060000803',
	'102400000070000452090000010500060200000834000009010006010000080258000070000007905',
	'104000000809060000030018006040009010000603000090500080200850090000020107000000805',
	'104007000800000200007500680000040006020090040700060000089002400003000009000300807',
	'105000000000094020000003070840930700500000009003082045080500000060840000000000107',
	'105007000000062500709005800800700040400000007070003006004500208007210000000900704',
	'106000090700009360040602001000001620000040000078200000800306010053900006060000509',
	'106500000000008204500007600200004080000805000040300002007900003903700000000003709',
	'106902000040150800000000000001069074072000190490310200000000000004071050000503409',
	'107080003405901000000000000258000000040602010000000792000000000000205609600090507',
	'107500000005004378000200005032070050000000000010080420600002000974100200000006107',
	'107600930000012500000900400960180000000000000000037069001006000005320000094008601',
	'108400000009800010546900000900000180003000700061000009000004896090005300000007401',
	'108400093000009000005230000800900000350000041000008002000017600000800000630002708',
	'109006000000480000058003076602000000007010400000000209980300620000047000000200305',
	'109060000340000090000000430500830000030649070000021003063000000090000025000010308',
	'109300000060010000000900780090085107700000003801790020017009000000050070000004905',
	'120005300006000005000072000304050009000010000200040503000860000800000400001900026',
	'120008030007300500000270846003000000200000005000000700842039000009006300030100059',
	'120076500400000100000540300080000000700215003000000050009084000007000004008120065',
	'120560000309000400000300068406100030000000000050007601930002000002000806000045029',
	'123060400007800600600090007000100000000254000000008000900010008004005700005020136',
	'124000070050037000006810000000500320500000009041003000000085100000960080060000932',
	'140053009005000003000900050000590810900030007028074000080009000500000200300760084',
	'140703250020000000009000004080034075000601000690270030400000800000000040016405097',
	'140720050000084000900103007000000600290465031007000000800309006000670000070042019',
	'140839000730100000000000030015060300960000042004010690020000000000001064000623017',
	'140900030502013000003600000020004007009080400400500020000001300000370506060009014',
	'145006000000000000600753100006380002090000050800049300009631005000000000000900437',
	'150296000000000090092430000000002034600000007920600000000024350080000000000713049',
	'150700000020048006300000000004800090002401600070009300000000001600120080000007025',
	'156280090000000640000000500008630009001908700300045800005000000043000000090063485',
	'158006090000000001004801300860000000007080500000000012003504600900000000040200173',
	'160097030040500000000300000850000100002408500006000078000006000000009040030750069',
	'160270000020004901000000500000620400490000013006041000001000000608900050000017068',
	'160300000004950000000014002207000090600205001050000803500140000000087100000009068',
	'160720000000360000000000006078200060015000920090008430900000000000031000000087095',
	'160903080200000001040000700502000040800050003030000105003000090400000008090308052',
	'167009000009800050000000000002945078000010000970268300000000000080006700000500189',
	'170000000008041650005030018000010095000506000510070000730060200091480500000000049',
	'170000430006000790000000001200009080410278069060500002900000000083000200042000058',
	'170040030006700000005008006294000000000030000000000194400900800000005200020060075',
	'170200830400508017800700002000000700310000058002000000200009006040607009063001075',
	'179300050080000009230400000007060000000749000000020800000007065600000090090002138',
	'186000000000000003370400500700600100900253007004001008009004031600000000000000856',
	'186400000700908020005000000200100000460000059000005003000000900010703002000004378',
	'190000002800000000050239001907020006000304000600070803300892040000000008400000035',
	'190003000506049120000000000000500940009368700062001000000000000041730805000100067',
	'190053080005006009000000040001000002904070506600000100080000000200800900030910058',
	'190060005476002100005009000000000081000805000680000000000700300003200794200040016',
	'192000568004001000000020000403500002060000040200009106000080000000300600985000731',
	'200000000700059068050008100920400000005080700000007026004600070360790001000000005',
	'200000007097000041800096020005009410000317000029400700010270004970000830500000002',
	'200009708700653004000070090800090030001000400070040009030080000900325001507900003',
	'200030000000005723800906050000000006100809005400000000090104002746500000000060004',
	'200053709030000086007902000000000091001060300370000000000309600890000010702840003',
	'200401060079000000180790200004600030000010000090008400008049027000000190040107005',
	'200500300050870000000006000930680420400000009025034018000300000000012090007005002',
	'200710094700000800040983070450200000000000000000009023010692040006000007590037001',
	'200800014700010000001095800000000790006907500072000000003620100000040006610009003',
];

const medium = [
	'000023087600000500010700000009000004050872030800000700000005090002000006530960000',
	'000024600020039008060000024107000000008903500000000403950000040300590060006470000',
	'000025043200400076000000800000000065008659700540000000001000000650004007970830000',
	'000026003005000607000001940030000200709010806008000090084100000502000400300650000',
	'000026300000000074309004800060000003090401050500000020007600409950000000004810000',
	'000026713800500040200000000084010000600070004000060820000000005020007009567980000',
	'000028509000136000600000000290000100800000003001000097000000004000985000305470000',
	'000029300208000400095600000510000008000301000900000074000007640006000503004510000',
	'000030000000009106078000950080006001037010540500700060025000490309400000000020000',
	'000030000000207809000800103100003048000060000960400007702008000409301000000050000',
	'000030000040500980720100006000000120870000063039000000500004012083001050000070000',
	'000030000050000839000700010970100026030675090610009073080001000329000060000060000',
	'000030000204500060000010243129000000000000000000000827653090000040006501000050000',
	'000030009090000006007800230009100068008090500670002900016005800700000090500070000',
	'000030064306014700000700000004000502000908000207000100000007000001840307730090000',
	'000030096004109502000500000000000608002756900607000000000003000401205700730080000',
	'000030096900006200032500000590008400040000050001200079000009120005300007710020000',
	'000030120010004900000006073000109650000000000041305000190600000002800010073040000',
	'000030190000005000192000005000007048078000630420100000200000983000700000081090000',
	'000030217000009300013500000900000005105060802200000003000003480009400000824010000',
	'000030241020600700000000000050061008230040069100890020000000000005006090497020000',
	'000030600038000040000009750004903020000000000070401900089600000050000230002050000',
	'000030659000576302000000000000400120400000003079001000000000000308752000567040000',
	'000030802000500001000040750030400085040070010590002030016090000900001000203080000',
	'000030809000400070000900510468000005007080900900000281095001000030007000201060000',
	'000030840001402050900000000000600034700305008310009000000000002080201300093050000',
	'000030942970540000000009000000003800702010603006200000000300000000026075524090000',
	'000032160003400080006000000600005700810000052005200001000000800080007500027910000',
	'000032400061700002030000008000004010006503700050900000700000090800007320009380000',
	'000032460000604000000000012020009300009000600008100050230000000000201000041860000',
	'000035001907008000104000000020060008079000360500070090000000503000500209200910000',
	'000036090000001207020000001604900000050010060000003709300000080801400000070360000',
	'000036700005209006200000000026000008809000502100000670000000007900102300003740000',
	'000037000500000000007000932806003205050609080403800706169000300000000001000410000',
	'000038091900060000000000423010600002005000800700003050192000000000040009430950000',
	'000038106030000000000700350006140900000975000003062400021007000000000070704310000',
	'000040000000000032207001450600009041000306000590400006042100705160000000000080000',
	'000040000075108400000000006020000870010805090038000040200000000007906580000030000',
	'000040000903805000061370000712000009009000400600000725000098370000706208000010000',
	'000040001305000000002087400000100090026000140010003000004250800000000709500060000',
	'000040002803200000029701400761500000000000000000004871008406930000008706300070000',
	'000040007000200960051000008506007090000804000030600102800000350027003000300090000',
	'000040009059600000023000050800500000097000360000003005080000690000002580200010000',
	'000040020000003000076208400000001305009070200102800000008309760000500000020060000',
	'000040030600100050003000801706083000000060000000450709308000500040005002050070000',
	'000040036000003004005000900006002007907080301400600500004000800200700000590030000',
	'000040050600283000009100006106000000908000604000000308200009500000462003070010000',
	'000040050700000820002500030000056271000000000126370000040009600083000009060080000',
	'000040065000003001936000000000070010680010047040090000000000572400200000790060000',
	'000040090200700010040009008007006500002908600009400800900300080060001007030090000',
	'000040100482700000003000007100008204000904000704200005500000400000002936006080000',
	'000040273800010050000200001000000630000154000079000000400001000050060008167030000',
	'000040360000000100836200000290700500000000000007005026000006954004000000078020000',
	'000040800009802100070000003000013620200000004087250000700000050005601700003070000',
	'000041000370050490000000750000072040005184600020360000069000000041090026000410000',
	'000042000900000807005900000006009304200836009803400600000005100709000002000190000',
	'000042036005100000000800007047060001800000003600080940200004000000001400490650000',
	'000045003002307900400000200120000070006000300070000052009000008008709500200130000',
	'000045007607800405000200000300000510160000042028000006000002000206009701800310000',
	'000045200200300005001020040103000600800000003004000807020090700600004002009750000',
	'000046008000000060080000237060900041000501000310004070649000080050000000200430000',
	'000046713000000000000238509070000801000000000403000020104865000000000000952310000',
	'000046910000100020400000083029010000000834000000050870940000008080001000036470000',
	'000048000001000408008102060000020080890000052030070000040207900509000800000390000',
	'000050000008724500050080049783000000000000000000000368190040050005839400000070000',
	'000050000009401530400008000170000000290503078000000016000800009054302600000040000',
	'000050000030086057007100090005700080480000036060008900070009400820470060000030000',
	'000050002470029005000600470000000801030000040907000000083005000700390026200010000',
	'000050009069000400000009802905703004000010000700508603807900000001000530400080000',
	'000050009914003005370000000000006900008030700001400000000000087700800351800020000',
	'000050013041900000000061200170000504000000000508000037006790000000003650320010000',
	'000050018000600000982007000057000042000000000810000570000500396000009000340010000',
	'000050030000700810008931570010200000090506080000007090087345100053009000060070000',
	'000050062320006000090700800012007000000060000000300580005009010000400053930080000',
	'000050100000009483001024000010000008098000640300000010000980500289600000007010000',
	'000050100060100800000034900800000530040000010023000004008540000007001020005070000',
	'000050283200000000607003000020300809000000000403009050000600508000000002395070000',
	'000050300537000900000008005000005049040293010790600000100800000003000427009040000',
	'000050302030000000742000005020035080000904000090870060200000956000000010803060000',
	'000050360000731090050000000000900002304000507200003000000000050010698000086020000',
	'000050627600203000090000040004020050000305000050090800040000070000108004312070000',
	'000050800000000070930400600000008760070346050096200000004001032050000000001060000',
	'000050840801096000000780005130000070006000500080000092500048000000670309098030000',
	'000051269160000000050800010800000070000183000090000002080006090000000025516420000',
	'000052370063700000000080500000003706002000100806400000007020000000007830051930000',
	'000052600026930000000004090600000908400000005903000004070200000000019280001340000',
	'000054070037000000060002501090000004000647000800000030201400050000000290050230000',
	'000054700280300060050001030000008000036090240000400000040200090020003054001540000',
	'000057001004003090006940050652300000000000000000009362040095100060200700200430000',
	'000057409000000000000001078060040035405030901830020040780300000000000000901580000',
	'000057900080009000020040050900004008001000600200100007030080010000300040005970000',
	'000059020270000090610002008020003000900000007000400060400200053080000019090630000',
	'000059204000000050530700000008000390300010005012000600000005068080000000109360000',
	'000060000060008700209005000057000041010050080980000250000400103001700020000080000',
	'000060000064010009273800000640005000005090800000100056000002983900070240000080000',
	'000060000960010028042000010004006097700000003830900500080000270270040036000090000',
	'000060002700004010024900070000005086400812005580600000030006940090300007200090000',
	'000060020030017098402950000920700000000000000000001047000096703370580060050070000',
	'000060093080007400003000068000023000190405037000170000870000600001800070460090000',
	'000060250000409380000070009097600502000000000503007460800050000045206000062030000',
	'000060398000938000000000005200003800100846009004500007400000000000359000819020000',
	'000060753900010000000002010000007800206000907003600000040500000000070006687090000',
	'000060790000008000002490001000059106908000207506280000800024500000800000067010000',
	'000061005605040320009500040742000000000000000000000217020008500034020106500130000',
	'000061040006009087073000600000000230080000010017000000002000960760500400090820000',
	'000062000406000720090000008007800006200040003500001400600000040073000501000730000',
	'000063201002000000050004090709200000001609400000007908030900050000000800204150000',
	'000063400009500000000048009580000670020000040041000038600830000000004700008290000',
	'000064002000013670000000009625400003100000006300006298700000000063240000400670000',
	'000064210814230000000007000090002703300000001507300090000700000000083147076420000',
	'000065049035007060000008000048000000600000005000000170000200000070800320980610000',
	'000065080049000016300000900000008701000257000407600000004000009730000540090520000',
	'000067400086050000170003000000600900047000610001002000000100084000090270005470000',
	'000069000000000029008301040001053000059000160000190300090806400520000000000570000',
	'000069230620030000000700000268000001370000052900000376000001000000040025037520000',
	'000070000000006005000500486800000072200138009530000001973002000600400000000090000',
	'000070000009300050415900063200000000067090520000000001670001295090007100000030000',
	'000070000013000407800000035000900084020641050940003000160000002507000360000050000',
	'000070002000900405020000060052040609006153200401020350040000080208004000900030000',
	'000070020000000038026003075304600500000000000005009806930100450540000000010020000',
	'000070050500008130004105007180000500000406000006000098200503900043800005060010000',
	'000070400200010370300800000100009600600000003007200004000004001051020008006050000',
	'000070450806000000000009103490100700000503000005002019207900000000000601068010000',
	'000070500079508300000302078300000002015000680700000009850106000004705860007030000',
	'000070504754086000600500000000000841007000600849000000000004002000850976902030000',
	'000070509043008000800000030900600820000020000038004006010000003000700260702050000',
	'000070923000800000002090060620000040000746000070000035010060700000001000456020000',
	'000072004920100000500800320693000000000000000000000486079005008000001093800260000',
	'000073650200400000050008002800020005002000900700030006900600040000005007035780000',
	'000074150605000000004000960000810020700000001080037000097000500000000204043180000',
	'000074206004012000000000080001080007502060803900030600090000000000720100703640000',
	'000074285000000000000006790460080001000709000700010038035800000000000000184950000',
	'000075000053020100000400056500000820090050060062000007920006000007040680000180000',
	'000075000500001300920000000046500007700060002300004960000000096004600001000950000',
	'000075801000000050208010400000000089700103005620000000002050307060000000405620000',
	'000076050000050310400008007049000001700030005100000740600800004071040000030610000',
	'000078000006001870090600540007000000030715020000000700041007030089100200000960000',
	'000078640000000000000406528904000300000000000008000701435607000000000000062390000',
	'000078900000000085000009207803900540009000700064001809507800000480000000006130000',
	'000079203370120000000000001009300500830000029007005800600000000000061048701580000',
	'000080000003000070420006500506193700000000000009572106002300045090000800000060000',
	'000080000027300000000001950300000706590000032702000008064200000000009460000010000',
	'000080000030100006876009000010000070008205900050000080000600742700001030000040000',
	'000080000050400001100006205004900007000648000600007500306800002800003070000050000',
	'000080000278009000091200000020305007100000009600907080000002640000400372000050000',
	'000080000365070000008009261030000500506000903002000080691500300000090612000010000',
	'000080000600000028043200100000007005005326700200400000006009870370000004000030000',
	'000080001309200000005010030006390000090000010000072500020050300000009704400030000',
	'000080006600400070028697000000000314080000020513000000000159640050004002100020000',
	'000080010002605094300041500068300000000000000000007120003150009750406200090070000',
	'000080040300107000600000097200009075050000060780500001460000003000605009070090000',
	'000080043047001069300009000008000004000312000900000600000100006890700530750090000',
	'000080047000300000000015032003500400006030100008007500950240000000001000460050000',
	'000080050500002600000006030074600900081000760006009480010200000005700004020090000',
	'000080170400070000700000092030940206500000009902058040270000001000090004084020000',
	'000080230000000074460500001000075010507060309030890000200003048310000000076040000',
	'000080290350900000000040006003004009090603020500200100400090000000005064038060000',
	'000080301001320000000004080006800700104000906009003800090400000000037600503090000',
	'000080600700004000000000325002300807090805030308001200834000000000700003001090000',
	'000080600839020000000007050700300000604090203000008006010800000000050971003070000',
	'000080607000030050060005081800001000300859006000400009520900060040010000903070000',
	'000080700000715900760300001130000000005000200000000047500007082009651000006020000',
	'000081064150003070000000003000002006904000308600900000400000000090100047510790000',
	'000081090000060052000700410098000000003906500000000720087002000910040000020630000',
	'000083625000090007060007390279000000000000000000000256012700040700050000453210000',
	'000085004592000300000003010300000500004050600006000002040900000009000436700160000',
	'000085900000000415000300200400090760500000008036070002003006000654000000007130000',
	'000086024706000000000000659009300008040000030600001400374000000000000807290670000',
	'000086049600000000009200560500148006000000000800632004078001200000000001140570000',
	'000089001500000007009006805610005000000000000000200093807400200900000008100930000',
	'000090000000073600509060170050400006068000750400006080076080502004620000000040000',
	'000090000003800460080000200040002870905080106078100020002000050054001600000040000',
	'000090000109870000830020400620000010300000007040000032007030095000069703000080000',
	'000090000203008000000462170000000089730050041690000000079384000000700304000020000',
	'000090000802000003006100050000057060008020300020610000050008900400000705000070000',
	'000090003030674000050000070900200001600709005300001004070000010000467050400010000',
	'000090003603800020000016904936000010000020000040000398309760000010009506700080000',
	'000090006900000250010200908000300009084000730600005000107004090093000001400050000',
	'000090040002800560060100820501000000000207000000000605086004050019008400070030000',
	'000090050009700208680100000310000800000050000006000039000004086907008500050010000',
	'000090051040000030850204000700006000025000710000700004000301097080000040910050000',
	'000090062900300070000002050014020000060508040000070690090800000070001004380040000',
	'000090100906000050300507900500006000040000080000900004004208001010000703002010000',
	'000090370003701500490000000900050000004182900000060008000000016009306700065070000',
	'000090700070000006196800000080051000002070600000230090000005183800000020004080000',
	'000090840090341200000000001000000108560020037107000000900000000001682050042050000',
	'000090852070005600000000003008050924000609000912040300600000000004100030327080000',
	'000091034030400900000000082610500009000030000900006015760000000001007050540910000',
	'000091750527040000000000000000000319350070028182000000000000000000050234045980000',
	'000092400080130000004000600820000006007903800400000095008000900000028040002740000',
	'000092504000000000540016300970000400000809000001000079008960047000000000107430000',
	'000093004900000060020167900740000010000872000030000029006725090090000001500940000',
	'000093150000000000000145627003000070507000806040000300394256000000000000056730000',
	'000094000520010800430500002700000020000359000040000009300005064007040018000130000',
	'000094020000000008000002490040080210150030046023060080035600000800000000060920000',
	'000094100800000000090300205501000000700010002000000409103009070000000003002540000',
	'000094500006100030030000801310002400008000600004800073901000050040001200002980000',
	'000094706970000010000001004010700038000000000350002090800900000090000065706140000',
	'000095000190000000040007920900500007058060230200003009075900060000000083000280000',
	'000095204034000105020000000008051009000709000700420500000000080901000450402510000',
	'000095400900300520001600030000000090680020053010000000070002900058007002006580000',
	'000095408900000000006020010079000001800000006400000720050080900000000007607340000',
	'000096480180000000000820090003007940070000060091400200040078000000000079027950000',
	'000097050000000001857401200795000004000000000100000862009508123300000000010240000',
	'000098260300000000000650080060000540900205008054000090040086000000000007025970000',
	'000100000000060090034009201029000700040010050003000680108200540070040000000003000',
	'000100000000060917003790006006000072070000050390000600900056200485020000000001000',
	'000100000000867015186000000620370000070000040000052036000000928850231000000009000',
	'000100000009000086000597000075800400200000008001005620000941000840000700000003000',
	'000100000400000506203045000300050298000000000957020001000860403801000002000007000',
	'000100000800020006320000508004300000030917040000002900607000029400050007000003000',
	'000100000849200000130080007006800010900615003010004200500040098000008534000001000',
	'000100002040600300570020000000000054405090106160000000000040035004003020900008000',
	'000100004100004069003057000975000000010000030000000581000420600280700005700001000',
	'000100007000056309700400006007000080095060730040000600400003002102690000900007000',
	'000100020030027600208500000614002000000000000000600715000005804006210070050009000',
	'000100079000000000000607281096004100830000027004200690315408000000000000960003000',
	'000100406500000800000065701090080000400000009000030010904350000007000002802004000',
	'000100603930800000000000240850009000001708900000200074073000000000003027209006000',
	'000100702000005106700080040600000030007608200020000001070060005803700000509004000',
	'000103005801000002030960000000006850000304000027800000000039070600000901200407000',
	'000103040590000006003020800860230000000000000000061059005090400900000083070602000',
	'000104000081070390000000010030400007200090001500006080050000000074080560000305000',
	'000104800000900210300000000030002100860000074001400020000000007084007000009503000',
	'000105000000030650800000240000021009080000060700640000027000003068050000000407000',
	'000105908058060730000008060000000190100000002036000000040300000082090340903406000',
	'000106000000000965060200470472000500000070000009000736046003050387000000000701000',
	'000107004008040000000508019001005700030000020004200100520701000000090200900602000',
	'000108000080000006005400837238000010400000005050000783124006908800000060000804000',
	'000108074020000000400970030004500000063000420000002500030017005000000010670209000',
	'000120008000008000050000047008700405010000030907004200740000090000800000300095000',
	'000124000502000700036700000050000006009638500600000020000001680008000401000582000',
	'000129700050000000010054600038090200400000006007040810004610020000000090001972000',
	'000129703500708090000000000020900008093000560100002070000000000060207001908613000',
	'000130000000006070090007028740000000016070230000000014230700090080600000000019000',
	'000130000700000000009004803403276000600000004000543609504900700000000001000025000',
	'000130006009040000020000950000300008307050602500002000094000010000020800800097000',
	'000130080530084000002000000740000000029805670000000012000000200000290041080057000',
	'000140000000000960359000070000008096083060250690300000070000129014000000000051000',
	'000142000950000001006900020000000250500070009061000000070008900800000034000713000',
	'000143506000600000000008270150300400300000007006004053032800000000009000605231000',
	'000160094004000003000023001030000006007605400600000050800390000400000800920016000',
	'000164000005000030070000002004500200150000047006003800300000010080000500000928000',
	'000175490050000000000689075092000000100000007000000580780914000000000010031268000',
	'000179600200060030040000005039750000000000000000086340900000060080040007006598000',
	'000190500401000020003560010046008001000000000800300940030025100080000705009081000',
	'000190603060300005100008009300000400040080090008000006600700004900003020703029000',
	'000192000019580002000000050090000705380000016607000080060000000400067590000839000',
	'000200000000009586800070120008000005002743900700000300089020003253100000000005000',
	'000200000000010078065030000004000035780604029150000400000080750930070000000001000',
	'000200000000500630090040807000400176200000005571009000603080040089003000000004000',
	'000200000030000080807940060300050008790408052500010006080026509010000030000004000',
	'000200000130900400090000007200091004060030050300420009800000060005009038000003000',
	'000200001000570030240010890100000320030000070024000006052090083070082000900004000',
	'000200003000000250430800006904700000020030060000005701500003092083000000600007000',
	'000200006000500007060039104040000200030010080009000040306850090500003000700002000',
	'000200007000090004400173209800000600010000040009000005504716008200080000300009000',
	'000200007056040000030605002823000004001000300500000289400302090000090820900008000',
	'000200009300007080087000061802000000000413000000000907950000840010500002400008000',
	'000200048000008300000350620045700000700000005000002190051076000006800000470005000',
	'000200050200100900070000140706904000000000000000708605059000030003007001080002000',
	'000200080000401900070000002009003170001080500028900400200000050005108000040002000',
	'000200300030840000020000109000010054000507000560090000108000070000064090003008000',
	'000200509200019000950060200000000354000000000714000000007080023000950001108006000',
	'000200840200000007010007500020083004000725000600190080009400060100000003057001000',
	'000203060032000001000500002029000000007805600000000790600001000400000980090308000',
	'000203100080000450000000090090120005007504900400097020040000000072000010006305000',
	'000203900000004083093500100700000000080602030000000006006001720570400000001305000',
	'000204900840005700000700100360000000002000500000000073009002000005800049008501000',
	'000205030019000000200040600600007000495000726000900008007010003000000170060504000',
	'000205134000000008000008509005000041002591700670000900507300000800000000149806000',
	'000209430008000500050600002040700000800010003000004080400007090002000700075106000',
	'000230009000000800000068710109000208700050003304000605073890000001000000500023000',
	'000240300000001000724000058000300425000000000957002000140000839000500000006034000',
	'000240700000065104804000006000000067000903000920000000400000301305870000007031000',
	'000248076007000009050706000000000014004070800890000000000503090500000600680419000',
	'000250003260497000000006490008000024000060000910000800079600000000725049500049000',
	'000267310060000000000000084001020400608953107003040500410000000000000040092174000',
	'000280003092000001080005000003004210000050000054900700000600080600000130400091000',
	'000280300400001207900000046010000000006759400000000030890000004705100002002098000',
	'000280400000700605900000710030000041000020000260000030025000004701005000003012000',
	'000280507000003000620007000060000008370060021900000070000700083000900000704021000',
	'000286000000003240008000037000007004049000820600400000210000700095700000000892000',
	'000290001810000000009050706076009204000080000901400650204060300000000069100073000',
	'000297000000000950000060083060012400009040700004380060410070000093000000000154000',
	'000300000000000086300196042010020098000609000790040010850962001130000000000001000',
	'000300000000005060705090280208030001000487000400010308013040502050800000000001000',
	'000300000000005070109040008086900700900080006005002810800020401020700000000003000',
	'000300000000005403000001607010000706350080049607000080806400000403200000000006000',
	'000300000000020403024000007260003000070492080000800072100000940709050000000008000',
	'000300000509000710300058400000001060230000087010800000001780002043000508000006000',
	'000300000700060008000000562006908000059406180000702900137000000900020004000007000',
	'000300000740000500000071600600029350900050002057430009009560000008000026000002000',
	'000300004037600000000009081020000000005293700000000050390500000000007260400001000',
	'000300010905680000104000000003010060006408900020070300000000702000063108050002000',
	'000300048000074009029000000800006431000000000132900006000000170900830000640005000',
	'000300060500000000000605192792000005008000600300000219821907000000000001070008000',
	'000300071004000005000175000060010704300000009409060080000684000600000300250001000',
	'000300090200000038300024500007000600000715000002000800005840001140000003070006000',
	'000300280000100903000049060800000001009070500700000004010980000507004000084003000',
	'000300417700000000000502006020040079090000080510090030200401000000000001981003000',
];

const hard = [
	'000070100000000300207830509700905230003000800089307005608053902004000000002090000',
	'000070300000500708800300090720800004900030007600002081010006002504003000006010000',
	'000070860000009001006580009695000000008000900000000513400015600700300000083060000',
	'000070900490200016630000002009600000300812007000007100900000084740005031001020000',
	'000070920000960005200043006750000000061000530000000062600710008500039000027050000',
	'000071050509000100060000000200008400004752900007900002000000030006000204080630000',
	'000071203802090600500060000000009070080000040090400000000040006003020408104650000',
	'000072000007308004200100000070020080081704520030080040000006008300805100000430000',
	'000073006049100005002090000005600900010000040007004500000080700900007820800320000',
	'000073600000200005009508200047009008390000056800700940004307800900001000006850000',
	'000074201082051060307000000003007004000000000500200300000000409020430670904710000',
	'000075080090000000700001209004058000605000403000360500109500006000000040070690000',
	'000080000000703900030060148050007000087902610000800020916020050005301000000070000',
	'000080000005006100080410709010000005400509001500000040203064070007800300000050000',
	'000080000279300000053490700900000400580000039004000001005043960000009572000070000',
	'000080003002006590507000068009100350000020000013005400490000207051200800700010000',
	'000080014900000000120094503000006030006809700030500000502370096000000007890040000',
	'000080043300040200760900500000038000001207800000650000003002057005010008490070000',
	'000080060000053402007100009030000048006090300410000020100009600803240000040010000',
	'000080064000451300000000120204100950000000000039005607027000000006514000340070000',
	'000080070080000050007001402861270900000000000004039861608700500040000020050060000',
	'000080090009400000000001058080210070001647300040098060310800000000003700050070000',
	'000080170800700040650120000020069000000302000000840020000036019060007002049010000',
	'000080200070060080500003710400020800000406000003010005094800001010070020007040000',
	'000080203020040000608200000006071508800000007103520600000002405000010060405060000',
	'000080730000072600000000528001046070080915040060230100173000000008350000045020000',
	'000080900000500004005600718406000300000739000001000207859001400100006000004020000',
	'000080900006700003950030041070003000003000800000200010280050037400001500005020000',
	'000081005080004209000000070209700000600803002000009406050000000702400010800260000',
	'000081073000054600000000054480200030019000720030009048790000000001840000350190000',
	'000081500020000000307006100002300069060020010910005200009100704000000090001840000',
	'000082407000000238000004010090500003100000002400007060030700000245000000709140000',
	'000082607700600000000509410219000500000000000007000238042806000000005002508120000',
	'000083065020004100405100030300000010200918003040000007030001804004500020150840000',
	'000083902300100000200007010020005090900218003060300070030700001000001009507820000',
	'000084037400000600012900000000070840000406000047090000000008160004000008680310000',
	'000085690000130048000006005005000032000318000890000400500600000470051000036470000',
	'000087000207000000004901260000000104040328090906000000071806400000000503000190000',
	'000089001048035097090000650200001000000070000000500004027000010560210740800750000',
	'000090000000007241000100076060050010000903000090080050640008000182700000000020000',
	'000090000003006280640700010912000000007000100000000936030001094024500700000080000',
	'000090000601840503002100008100000050020070040050000009200006400407021306000030000',
	'000090008005100700680507001540760000000000000000084027200403085001006900400020000',
	'000090008060430029095000000350008690000000000079300054000000210980075040100020000',
	'000090012000065000000100803200004730890000026067900001702009000000810000910070000',
	'000090027600000000090260140037000000000953000000000360065048090000000002940070000',
	'000090030608240000239800040900000620007000400063000009040008751000064302090050000',
	'000090034200508107000010080001850000003241700000079800060020000107405009850060000',
	'000090038009000060460700501200001300000030000003500004306009052010000800890010000',
	'000090040002006001015804600093000120000000000061000790006903870400100300030080000',
	'000090045090500100500008030000089000002703500000620000050800009003002080720060000',
	'000090100140060000000301602200000059070904010690000003907205000000010027006040000',
	'000090406070800050810006000060052300300000009007310020000600012090001030103070000',
	'000090630086504020000000000600048000004000100000930005000000000070201460051070000',
	'000090700006705000000000012030800095900241006610009020890000000000604200001080000',
	'000091005001000790064500000075010200000209000009080510000006320047000900600940000',
	'000092060010000000706000023204600000000000000000001409890000607000000080050360000',
	'000092100402510609000600005050000700000758000004000010700009000205061807001370000',
	'000093027000000300000700581900007000018906740000800006526008000003000000140250000',
	'000093600010000079900800000071500086000000000840009530000004008580000020004210000',
	'000094000840260000000000450093008620600000007024300980031000000000027043000530000',
	'000096050000078000005000871500000040013000290020000006731000900000910000050630000',
	'000100000005006008920000004060500070003627500050004020800000065700900200000003000',
	'000100000080050001500008923050000006916040285200000010195700008400090070000003000',
	'000100002023000608570020000000300025005902800780004000000060047207000130600001000',
	'000100005034000800006230700040000290000723000025000080007042600008000410400001000',
	'000100007900630800030097001059000603000000000304000250100320090008076002700001000',
	'000100008090030000000954600360000950800040001017000082006581000000070020700006000',
	'000100020031470000700000060000034106090000040406850000020000008000045670060002000',
	'000100080000004200000069143072305400000000000003901650489210000005400000010003000',
	'000100082300000040150000700000005009200736001600200000007000038020000004960004000',
	'000100900000209813000500060800000304490000082205000006080007000762408000001005000',
	'000102548000050000005038020043000107000000000608000490010280300000060000486309000',
	'000105320007040000200006500000080003080000040500060000004800002000070900093601000',
	'000105800680000000040000570700019300000302000003570001056000090000000012008904000',
	'000108000000030509700000040204000710980267054037000902040000006109020000000309000',
	'000109040000000260000036008400200100025070630001005009200350000089000000070802000',
	'000109702002630800000000050000000985035090670489000000070000000008016200203408000',
	'000130000009002400000000360084029053300501002520840690043000000002400900000015000',
	'000134000000080900208000003026000009800359002300000780700000805005010000000542000',
	'000140009000700430030006070070000500651080743004000090040900050013002000800064000',
	'000150062100000900300004008020300004407000803900008020200600009009000005530049000',
	'000160400000005902000002360050000618100000005298000030027300000809400000001029000',
	'000160904090000013000300870060000400079080250004000030038009000650000040901073000',
	'000170000090000034570002001300000920000208000024000003900700052760000040000024000',
	'000190007007600082400000900800010270000020000015080003003000006760005800100068000',
	'000190080000000009009800413864000000002050100000000347396005800400000000050036000',
	'000190870004000002700350000020000640009060300053000080000083005200000900078029000',
	'000198006903006000260000001005801007000070000600903800700000028000300509500687000',
	'000200000000049100600000028040002309032154870807900010710000003003470000000003000',
	'000200000003704050204900806041000703000000000507000680906007501010503400000009000',
	'000200003543000000009004160030052000006040800000160030057800300000000742600003000',
	'000200017000003200000010408200501006004000800300902004103020000002700000480009000',
	'000200040025060090000040605750820030800000002030059086207090000040070850080004000',
	'000200067045090002000061000006000075009147600420000100000310000500080730960005000',
	'000200079080040321000008400060003004300000008700800060004900000579080040810005000',
	'000200097010070600930100000090800025000000000180005070000002013003010060820004000',
	'000200300290000007763400008020030000008000400000040050900007836300000021006008000',
	'000203000064000103705000000020001600000726000008900050000000308902000460000107000',
	'000203001030409027000000000083000000270841093000000760000000000190602080600904000',
	'000203590820000000000900001070300480305407902014002070400005000000000034032108000',
	'000204700670000052002070100000047206000090000307860000003050600250000084006408000',
	'000205700705000040480000100900007000000020000000100006004000013010000802002609000',
	'000206010000008607500000009045060020008020100010040970100000005607300000050609000',
	'000207901040000030900040067000003008090476010300500000460010003080000070705304000',
	'000208000002040000600070015900000034030000080850000001710030009000080300000605000',
	'000209007002840005000060002060000530900050006013000040300010000200074900700302000',
	'000215000900800650010000007000050804000489000604020000500000090093002006000934000',
	'000218000000000000045670201607030005004020600200050903302049710000000000000162000',
	'000230500309580060050060090045000100100000004006000350090020010020097805008015000',
	'000240030020960107800000020760019000008000600000850071050000003603081090080094000',
	'000243081000600070000009003600000030073050260090000005700500000010006000860731000',
	'000248106900000000000000857020005003003000700700100040638000000000000009109673000',
	'000250803270008600000000000100002009040090020700500004000000000001800065806041000',
	'000270094000000003000004618050009100002040700007600030726300000400000000190028000',
	'000274090000300000300005100490000070850000062010000058006500007000002000020741000',
	'000275000030900870607000000405700000020010050000008203000000608043002090000597000',
	'000280090002000001305001000200008914400090005519300006000700509100000700090054000',
	'000280906000079040040500200500000000670000025000000003001003070080420000907061000',
	'000290370009300000000070008508000002070050060400000107100060000000003700063081000',
	'000290600000007940000003008050900021401030709980001050200600000098100000004079000',
	'000290700090000803500000029300076500000020000008350004280000007904000080003064000',
	'000300000000002065421900003936700080000000000040009356800007492690800000000003000',
	'000300000000050024002601503460000000030809040000000062701206900920010000000007000',
	'000300000000095604007200080030000008658000319900000070060007100204150000000004000',
	'000300000005800007849000050010050200600207005002030090050000978700008600000004000',
	'000300000041800900800000056020001008907000602100200030290000001005009270000006000',
	'000300000060045001103780000702000850090000010086000702000038904600910070000002000',
	'000300000065000070001070629009600001003958400600007800918040500030000210000003000',
	'000300000094000700500082000030070600780050034006010050000430002009000460000007000',
	'000300000200007000390006040001090620008000500059080700010200063000100009000003000',
	'000300000301050980580000004030040006000761000900020010700000029059070801000006000',
	'000300000902840000000020036006000027005090600820000400450070000000082304000005000',
	'000300001000508603007090408380000000000623000000000069708060100204805000600009000',
	'000300004046210080000000709680000200000192000009000047907000000060027950800004000',
	'000300006183000700000080002002058901600020008804970600300060000001000265500004000',
	'000300080000000320130000040010080407000517000903060010060000092094000000080001000',
	'000300094700040000064002300009000003005108200400000900001700860000080007280004000',
	'000300615000004230060000409004001060000829000050400700501000040047900000698003000',
	'000300807400020000009000600300400070605070901040009003001000700000060002904008000',
	'000302870285070000000009000010000004064090210900000030000800000000050621052907000',
	'000304502502000034030000000350048001006090300900630045000000050210000408809405000',
	'000305090074900000000001302007000038500294007160000400601400000000008670080603000',
	'000308000021004006007009405109000040400000008060000901703900100200100580000802000',
	'000308402001574000000000080008200070100687005030001800050000000000895700709106000',
	'000309100000005409000010038000000075070568090190000000360050000701900000009802000',
	'000310700401200080090000004000003001034060950600900000700000090050009103003085000',
	'000320000000050089001600000003000452040532090152000300000006200680070000000013000',
	'000320780007009001030000500105007800000050000003200605008000050600400300029013000',
	'000346900000001054300800001008004002070000080900700100800007009610200000005183000',
	'000350800593008000700000030850019000016000920000530081070000003000800249009061000',
	'000351760004600000000008050006700000210000086000006100040800000000003800082469000',
	'000370640000500002003002000020080010400090003010040050000400800500007000039021000',
	'000372600003000010005840020000004008080000040700600000030018500090000700002795000',
	'000375002000064000005000087350000000007493800000000036790000600000120000500746000',
	'000379000700006300003100006007000400350040018002000500100004800005800007000791000',
	'000382000000000100000570032060000074409030506820000090270046000001000000000823000',
	'000382460002500000000600075380000600490000052007000043740008000000006700028947000',
	'000390024002801006800000000750000000068030170000000032000000003600703400930046000',
	'000390070007020580100000009040000205075000310903000040300000001059030700020069000',
	'000398025200060087009000000406080000000209000000010802000000500170020006350976000',
	'000400000000000719009530800603000080750020043020000607005016300312000000000005000',
	'000400000046000970000007006007008263030070090264300700100800000023000480000001000',
	'000400000081095000402010000040068001070953040600140030000070908000580310000004000',
	'000400002056000070072965000001020000040000090000080100000832960020000480600001000',
	'000400007000000843200083050380000700005107400001000096090230004462000000800001000',
	'000400009034000750090730200002100090000849000010007400009078040073000180500004000',
	'000400017160050200000207800042001000900000005000700490005306000006070058480005000',
	'000400050510608000007000000709100400600080003001004702000000900000206087040001000',
	'000400060700065080004000700040100300500090001001008090002000800010930002090006000',
	'000400070006510020000927104403000000000801000000000402804759000020034800070008000',
	'000400095002901800000080100013005760200000008096800510008010000009304200530008000',
	'000400260000001008100000040400610020030040080090087004040000002600500000017009000',
	'000400300040000097800600000000790520700328001086045000000007006920000040001004000',
	'000400350000685000400000089703500100000010000004002907370000002000926000042007000',
	'000400500000029714700000062200040100000361000003090005530000009427980000009004000',
	'000400500089500061100600040000007803000386000803900000090004007740001290001005000',
	'000400930000030041040005000350001790600000008087200013000500020290060000036004000',
	'000408500000000014000020006570083040046209830030740095300070000420000000008302000',
	'000418900060390001900000040009080000052000190000050300030000004600035070005174000',
	'000437900000600340002000005700200410000703000021009003200000100013004000006198000',
	'000450000003007205700002080000000802007103600308000000090300008502700100000049000',
	'000450000570023000600000900000500140700010009012007000003000006000230058000075000',
	'000460010000008000080700064195000008002000100300000695930004070000900000060032000',
	'000460080007000010200700900000003460708246503064900000003009007020000100070054000',
	'000470310080051047000000002025036000000000000000290150200000000430520080058013000',
	'000490023000000000742600050507060090000000000090080207020001479000000000980043000',
	'000490500005803700090000000050600207100050003802009050000000040001207300006045000',
	'000490700000200060410600930000000650000807000025000000037006092050001000009082000',
	'000500000005002630069080000032050009078000120900070340000090870093600500000008000',
	'000500004200009560009100038050900000003401800000005040820003100076800009300007000',
	'000500014643000005000002900095003000038000450000900380006700000900000731570001000',
	'000500020000290608900004075000000039008000700610000000830900006201076000050008000',
	'000500049000007360070000000200870090760030018080015002000000030024700000890002000',
	'000500060600004238000000009000003600530000092004100000100000000298400001060002000',
	'000500070006010000080470610300000000095604130000000007052067040000040300060005000',
	'000500081200008070500007200000090000003205600000030000005100007090800004670004000',
	'000500200090000010500003804304100000700936002000004107901300006030000040005002000',
	'000500400050090003701600000210000080900030001080000064000001809100080040003002000',
	'000500806005083004700000000000002190260050078097800000000000009900160500108005000',
	'000500806070004300300900070800000000790080042000000005020005007007400060901006000',
	'000500964000000000000084005042091700003040200005260410500730000000000000381002000',
	'000503001701060200000000630007002009200709003900800400072000000008020904400601000',
	'000503941910600300000009680050800006000000000200001090095400000007006054462308000',
	'000504002030207400000061900000000704002609500108000000005920000003405080900106000',
	'000507000541000006039000000250600004064000130100004085000000410300000268000302000',
	'000510700009080605000047030000000308081000470703000000020360000607050900004079000',
	'000519020009240800000000049600000700500020003007000001370000000005087400040152000',
	'000520070008000000027009130102706090000000000030401208019300780000000900080057000',
	'000520308324000005000400000270000050001060400030000096000005000800000179603097000',
	'000520684070100005005089000090000150500000002026000070000470200900005040764092000',
	'000530000000000040358001002090000208027908510503000090900300851030000000000016000',
	'000530004001640950000000002870004200000000000004100093700000000029083700400015000',
	'000530400000000082500004310000040098007060100280050000054700006390000000001093000',
	'000536400000800020380200010007000004400090008100000900020001046060004000001965000',
	'000540037400000000839000000004005780600000004083400600000000971000000008150067000',
	'000548021800000000040920000085000006760000092900000380000034060000000008130865000',
	'000570040504000800900008050300004190000000000048200007020600005009000601010093000',
	'000573000090020008030900000610000970400050006025000034000005080300010090000639000',
	'000578000000000003020034750086002035002000800910800670073260010200000000000481000',
	'000580073050000200004603900003007000070408020000200700001804600006000010940016000',
	'000583000049000003108000000000600008490358017300004000000000905200000480000817000',
	'000590620000000005000408097302000000005941200000000501240107000800000000037029000',
	'000600000002009017490001560049700000000508000000003620051800096380900400000005000',
	'000600000100000500940075010000000069800509001320000000010240073003000002000008000',
	'000600000400003095750004030380070041000000000510020073070800019120300004000005000',
	'000600004028047000300800056003000090060704010040000500730008001000370840400006000',
	'000600004040783000002004009070000010001348900090000040200400300000539080700001000',
	'000600005005048000401000800008002096903050104540900200009000607000520900300009000',
	'000600009000001200601005040000080900047916580009020000010800307005700000800004000',
	'000600030034008602700000000800109246000000000621403005000000003206300150080007000',
	'000600034000100985704050600542000006000000000600000321005010403417009000230005000',
	'000600040000010200086200510578030000900000001000070935063007890009060000020001000',
	'000600070007010005000230690904000100080000040003000902049051000300060200060008000',
	'000600241000024069040000000000005030790060058030900000000000010460780000587003000',
	'000600304600000010017200900003070040800000001070090800009005170020000008108009000',
	'000600900000080200506007003700000080208040706030000001100200309003060000007004000',
	'000602340000000061000301702290006450000000000058400026805904000410000000062108000',
	'000609070000030064000000800012060408050708030803090720001000000920050000070806000',
	'000609358086003010000000000043200000008040500000005890000000000030900280792501000',
	'000610050060000109530004000470500000000030000000008027000900014206000070010053000',
	'000620010010304500006009040800000150001000700025000004030400900002106080040093000',
	'000620910500040200000801003400000700009507800007000001600208000003060002048053000',
	'000630000000400370480010600041000000230158046000000120006040093025003000000072000',
	'000630009003409120000000860000067400080040050004820000056000000038706200100082000',
	'000650003100038700080000960001000050003020400070000600016000070008570002700084000',
	'000680020000500807802040100000006900081000370006100000004070201108002000070013000',
	'000680093600000700592000600000900006065070210300005000004000137009000005170054000',
	'000687420000000009470000800608001000090248010000300508007000051500000000016825000',
	'000692000009000006100005030096000800204030107003000260030400001400000600000261000',
	'000697001300000586000050007001720090000000000040061800100030000537000009900276000',
	'000700000005000049204500006001007005407109203900200100700001802380000600000008000',
	'000700000173060000400093000005800019008010400390002700000120008000070962000006000',
	'000700001000026009200000740000170090018203450090048000056000008100860000300002000',
	'000700004600000000004213050003070008080549020500020700090487300000000005400001000',
	'000700006026000059100000000300041060049306510070250003000000004930000680700003000',
	'000700021003106900080000000072008000008000700000400650000000060009802400310009000',
	'000700030603400000040006500006007200700030005009200100008100040000004701070005000',
	'000700340000002007000008091038900054700000009420005670160300000300200000097001000',
	'000700450004000028000002307060370000007268100000014070201900000730000200059006000',
	'000700500084020060200000008000601940000000000027309000700000005040090310002008000',
	'000700600000001020000200301630090015010030070580010039304005000050300000001004000',
	'000702000000000034460009000080070005390010042500030010000100067950000000000405000',
	'000702930306051000000030007000000614000000000691000000800060000000320708035807000',
	'000704080000010905086059000000000642000431000849000000000190570605020000070506000',
	'000704200720106008000000007500200060001070900090001002600000000300609054009502000',
	'000705600005010090032000008051090000400000005000060340500000920020050700009603000',
	'000710000420000090006000003504031200010070080003250401900000700030000046000026000',
	'000715300000260010008090000020000560603000901089000030000080100030051000006347000',
	'000728300000000002000060597963004200500000003002500946218040000300000000009216000',
	'000730100090600400000009007080000691000000000357000020800900000002003050006018000',
	'000730405060500308000000000000000903230010047604000000000000000401003080506042000',
	'000745600000008100000000095100803047000000000620104003850000000007400000002657000',
	'000746800000000050004000007100007400690428015007900002800000500020000000005692000',
	'000750104900008000000006005020690000170000039000037020700800000000500001408069000',
	'000754210000100008000060400050000370780000061046000020001040000900001000074536000',
	'000760010905300000006090008360000700100000009009000041700040100000002804090051000',
	'000780050000000073002500008000048100840671092006290000500007900960000000020064000',
	'000790030200008905000000270040620000500000009000085060094000000102400008080031000',
	'000800000020005790980400006004060215000000000138050900800003059073500080000004000',
	'000800000270064000000003081100009060907010802080200004350700000000630048000001000',
	'000800000874600000006014070000000159080090030269000000010580700000001985000006000',
	'000800005500040090273000000000020780600703009097010000000000432010030008800006000',
	'000800010700093200000002900030000802200904003801000050004600000003410006010005000',
	'000800014000024307400000200200068000180050076000190003001000009304980000850001000',
	'000800020000006300015070000000008907038794260702600000000020630006400000040009000',
	'000800024206000190010000007000520700020701040008069000500000010039000402840007000',
	'000800030629501000800000506001000000083040260000000400308000005000706349040005000',
	'000800340000000008300025000002040037800902004130060800000210009600000000081004000',
	'000800407000200060300006000430000605067030280209000014000400008090001000601005000',
	'000800760000003800060072054035000000007090600000000580790250010001900000086007000',
	'000800769900200000001000800000000475040598010516000000003000900000005007128007000',
	'000801500300500000050070090200000084004237900960000003020060070000008005001709000',
	'000802000502000069730000000005008940070060080069400500000000097620000403000703000',
	'000802005080300200013000740030200900000080000004005080079000450001004090400706000',
	'000804032400000005005600001100000700000162000006000003700005300300000004510203000',
	'000804530000730802000050090006000300930000065004000200050040000403082000027903000',
	'000805040003070065040003008005700800100000006004006900400900010930010500060508000',
	'000806000000050090030000065004078900008000700003240100620000040010090000000604000',
	'000806074014090002007200900500000040040080050030000009005003800100020690290105000',
	'000807000243090000000000003650003200309020108002600034400000000000040356000702000',
];

function randomIntFromInterval(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRamdomBoardFor(array) {
	var idx = randomIntFromInterval(0, array.length - 1);
	return array[idx];
}

export const Boards = {
	randomBoard: function (difficulty) {
		switch (difficulty) {
			case 'easy':
				return getRamdomBoardFor(easy);
			case 'medium':
				return getRamdomBoardFor(medium);
			case 'hard':
				return getRamdomBoardFor(hard);
		}
	},
};