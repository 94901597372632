import React, { useEffect } from 'react';
import SudokuGame from './sudoku';

// const saveData = '200030000000005723800906050000000006100809005400000000090104002746500000000060004'

export function Sudoku() {
		const vr = new SudokuGame('easy');

	useEffect(() => {
		document.querySelector('#app').append(vr.getHTML(window.innerWidth - 30));
	}, []);

	return (
		<div id='app' style={{ height: '1000px', padding: '20px 0' }}></div>
	);
}
