import { Boards } from './boards';

export default class Sudoku {
	constructor(initLevel = 'easy') {
		this.level = initLevel ?? 'easy';
		const startValues = Boards.randomBoard(this.level)
			.split('')
			.filter((x) => '0123456789'.includes(x))
			.map((x) => Number(x));

		this.body = this.getFromLocal() ?? [];
		this.selectedCell = null;

		document.addEventListener('keydown', (event) => {
			if (!this.selectedCell) return;
			this.keydownHandler(event, this.selectedCell);
		});

		if (!this.body.length) {
			this.body = Sudoku.buildBoard(startValues);
		}
	}

	static buildBoard(startValues) {
		const board = [];
		let idCount = 1;
		for (let y = 0; y < 9; y++) {
			for (let x = 0; x < 9; x++) {
				board.push({
					id: idCount,
					x,
					y,
					number: startValues[idCount - 1],
					selected: false,
					supported: false,
					important: false,
					error: false,
					started: startValues[idCount - 1] === 0 ? false : true,
					s: parseInt(y / 3) * 3 + parseInt(x / 3),
				});
				idCount++;
			}
		}
		return board;
	}

	static getFreeCell(sudoku) {
		const cells = sudoku.body.filter((x) => !x.number);
		const index = Math.floor(Math.random() * cells.length);

		return cells[index];
	}

	static getBusyCell(sudoku) {
		const cells = sudoku.body.filter((x) => x.number);
		const index = Math.floor(Math.random() * cells.length);

		return cells[index];
	}

	static generate(n) {
		n = Math.min(81, Math.max(n, 0));

		const w = new Sudoku();

		for (let i = 1; i <= 9; i++) {
			const freeCell = Sudoku.getFreeCell(w);
			freeCell.number = i;
		}

		const s = w.solve();

		for (let i = 0; i < 81 - n; i++) {
			const busyCell = Sudoku.getBusyCell(s);
			busyCell.number = 0;
		}

		return new Sudoku(s.body.map((x) => x.number).join(''));
	}

	get isSolved() {
		for (const cell of this.body) {
			if (cell.number === 0) {
				return false;
			}
		}

		for (let i = 0; i < 9; i++) {
			const row = this.getRow(i).map((x) => x.number);

			for (let n = 1; n <= 9; n++) {
				if (!row.includes(n)) {
					return false;
				}
			}

			const column = this.getColumn(i).map((x) => x.number);

			for (let n = 1; n <= 9; n++) {
				if (!column.includes(n)) {
					return false;
				}
			}

			const segment = this.getSegment(i).map((x) => x.number);

			for (let n = 1; n <= 9; n++) {
				if (!segment.includes(n)) {
					return false;
				}
			}
		}
		return true;
	}

	getRow(n) {
		const row = [];
		for (let i = 0; i < 9; i++) {
			row.push(this.body[9 * n + i]);
		}
		return row;
	}

	getColumn(n) {
		const column = [];
		for (let i = 0; i < 9; i++) {
			column.push(this.body[i * 9 + n]);
		}
		return column;
	}

	getSegment(n) {
		const segment = [];

		const x = n % 3;
		const y = parseInt(n / 3);

		for (let dy = 0; dy < 3; dy++) {
			for (let dx = 0; dx < 3; dx++) {
				segment.push(this.body[y * 27 + dy * 9 + x * 3 + dx]);
			}
		}
		return segment;
	}

	getHTML(size) {
		const userSize = size > 700 ? 700 : size;
		this.createCells();

		const gameElement = document.createElement('div');
		gameElement.classList.add('sudoku-game');
		gameElement.style.width = `${userSize}px`;
		gameElement.style.maxWidth = `${userSize}px`;
		gameElement.style.height = `${userSize}px`;
		gameElement.style['font-size'] = `${userSize / 20}px`;

		this.createSegments(gameElement);

		this.createNumbersSector(gameElement, userSize);

		const contElement = document.createElement('div');
		contElement.classList.add('sudoku-controls');
		this.createLeveSector(contElement, userSize);
		this.createClearButton(contElement, userSize);
		gameElement.append(contElement);

		this.viewUpdate();
		return gameElement;
	}

	viewUpdate() {
		for (const cell of this.body) {
			cell.element.classList.remove(
				'started-cell',
				'supported-cell',
				'selected-cell',
				'important-cell',
				'error-cell'
			);
			cell.element.innerHTML = cell.number || '';

			if (cell.started) {
				cell.element.classList.add('started-cell');
			}

			if (cell.supported) {
				cell.element.classList.add('supported-cell');
			}

			if (cell.selected) {
				cell.element.classList.add('selected-cell');
			}

			if (cell.important) {
				cell.element.classList.add('important-cell');
			}

			if (cell.error) {
				cell.element.classList.add('error-cell');
			}
		}
	}

	createCells() {
		for (const cell of this.body) {
			const inputElement = document.createElement('div');
			inputElement.classList.add('sudoku-cell');
			// inputElement.setAttribute('type', 'text');

			inputElement.addEventListener('click', (event) =>
				this.clickHandler(event, cell)
			);

			// inputElement.addEventListener('keydown', (event) =>
			// 	this.keydownHandler(event, cell)
			// );
			// inputElement.addEventListener('focus', (event) =>
			// 	this.focusHandler(event, cell)
			// );
			// inputElement.addEventListener('blur', (event) =>
			// 	this.blurHandler(event, cell)
			// );

			if (cell.started) {
				inputElement.classList.add('started-cell');
			}

			cell.element = inputElement;
		}
	}

	createSegments(element) {
		for (let s = 0; s < 9; s++) {
			const segmentElement = document.createElement('div');
			segmentElement.classList.add('sudoku-segment');

			for (const cell of this.getSegment(s)) {
				segmentElement.append(cell.element);
			}

			element.append(segmentElement);
		}
	}

	createLeveSector(element, size) {
		const levels = ['easy', 'medium', 'hard'];

		const rootElement = document.createElement('select');
		rootElement.classList.add('sudoku-level');
		rootElement.style['font-size'] = `${size / 20}px`;
		rootElement.style['margin'] = `0 ${size / 20}px 0 0`;

		rootElement.addEventListener('change', (event) =>
			this.levelChangeHandler(event)
		);

		for (const level of levels) {
			const el = document.createElement('option');
			el.value = level;
			el.text = level;

			if (this.level === level) {
				el.setAttribute('selected', 'selected');
			}
			rootElement.appendChild(el);
		}
		element.append(rootElement);
	}

	createNumbersSector(element, size) {
		const rootElement = document.createElement('div');
		rootElement.classList.add('sudoku-controls');
		rootElement.style.margin = `${size / 20}px 0`;
		rootElement.style['font-size'] = `${size / 20}px`;

		for (let i = 1; i <= 9; i++) {
			const numberElement = document.createElement('div');
			numberElement.classList.add('number-selector');
			numberElement.style['font-size'] = `${size / 10}px`;
			numberElement.innerHTML = i;
			numberElement.addEventListener('click', (el) => {
				if (!this.selectedCell) return;
				this.keydownHandler(
					{
						key: i,
						preventDefault: () => {},
					},
					this.selectedCell
				);
			});
			rootElement.appendChild(numberElement);
		}
		element.append(rootElement);
	}

	createClearButton(element, size) {
		const rootElement = document.createElement('button');
		rootElement.classList.add('sudoku-clear-button');
		rootElement.style['font-size'] = `${size / 15}px`;
		rootElement.innerHTML = 'Clear';
		rootElement.addEventListener('click', (e) => {
			if (!this.selectedCell) return;
			this.keydownHandler(
				{
					key: 'Delete',
					preventDefault: () => {},
				},
				this.selectedCell
			);
		});
		element.append(rootElement);
	}

	getFromLocal() {
		return this.safelyParseJSON(localStorage.getItem('sudoku'));
	}

	saveToLocal() {
		localStorage.setItem('sudoku', JSON.stringify(this.body));
	}

	clearLocal() {
		localStorage.removeItem('sudoku');
	}

	safelyParseJSON(json) {
		let parsed;

		try {
			parsed = JSON.parse(json);
		} catch (e) {
			return false;
		}

		return parsed;
	}

	clickHandler(event, cell) {
		if (this.selectedCell) {
			this.blurHandler({}, this.selectedCell);
		}
		this.selectedCell = cell;

		this.focusHandler({}, cell);
		cell.selected = true;
	}

	keydownHandler(event, cell) {
		const { key } = event;
		if (!cell.started) {
			if ('123456789'.includes(key)) {
				cell.number = parseInt(key);

				if (cell.error) {
					for (const item of this.body) {
						item.error = false;
					}
				}

				this.checkErrorCell(this.getRow(cell.y), cell);
				this.checkErrorCell(this.getColumn(cell.x), cell);
				this.checkErrorCell(this.getSegment(cell.s), cell);
			} else if (['Backspace', 'Delete'].includes(key)) {
				cell.number = 0;
			}

			for (const item of this.body) {
				item.important = false;
			}

			if (cell.number) {
				for (const item of this.body) {
					if (item.number === cell.number) {
						item.important = true;
					}
				}
			}
		}

		event.preventDefault();
		this.viewUpdate();
	}

	focusHandler(event, cell) {
		cell.selected = true;

		for (const item of this.getRow(cell.y)) {
			item.supported = true;
		}

		for (const item of this.getColumn(cell.x)) {
			item.supported = true;
		}

		this.viewUpdate();
	}

	blurHandler(event, cell) {
		cell.selected = false;

		if (cell.error) {
			cell.number = 0;
		}

		for (const item of this.body) {
			item.important = false;
			item.supported = false;
			item.error = false;
		}

		this.viewUpdate();
		this.saveToLocal();
	}

	levelChangeHandler(event) {
		const level = event.target.value;
		const randomSudoku = Boards.randomBoard(level)
			.split('')
			.filter((x) => '0123456789'.includes(x))
			.map((x) => Number(x));

		for (let i = 0; i < 81; i++) {
			this.body[i].number = randomSudoku[i];
			this.body[i].started = randomSudoku[i] === 0 ? false : true;
		}
		this.clearLocal();
		this.viewUpdate();
	}

	checkErrorCell(selectedCells, cell) {
		for (const item of selectedCells) {
			if (item === cell) {
				continue;
			}

			if (item.number === cell.number) {
				item.error = true;
				cell.error = true;
			}
		}
	}

	getCopy() {
		return new Sudoku(this.body.map((x) => x.number).join(''));
	}

	getPotencials() {
		const potencials = [];

		for (const cell of this.body) {
			if (cell.number) {
				potencials.push(cell.number);
			} else {
				const rowNumbers = this.getRow(cell.y).map((x) => x.number);
				const columnNumbers = this.getColumn(cell.x).map((x) => x.number);
				const segmentNumbers = this.getSegment(cell.s).map((x) => x.number);

				const alphabet = [1, 2, 3, 4, 5, 6, 7, 8, 9];

				potencials.push(
					alphabet
						.filter((x) => !rowNumbers.includes(x))
						.filter((x) => !columnNumbers.includes(x))
						.filter((x) => !segmentNumbers.includes(x))
				);
			}
		}
		return potencials;
	}

	solve() {
		const copy = this.getCopy();

		let flag = true;
		while (flag) {
			flag = false;
			const potencials = copy.getPotencials();

			for (let i = 0; i < 81; i++) {
				const potencial = potencials[i];

				if (potencial instanceof Array && potencial.length === 1) {
					copy.body[i].number = potencial[0];
					flag = true;
				}
			}
		}
		const potencials = copy.getPotencials();

		mainLoop: for (let power = 2; power <= 9; power++) {
			for (let i = 0; i < 81; i++) {
				if (potencials[i].length === power) {
					for (const value of potencials[i]) {
						const nextCopy = copy.getCopy();
						nextCopy.body[i].number = value;

						const resultCopy = nextCopy.solve();
						if (resultCopy.isSolved) {
							return resultCopy;
						}
					}
					break mainLoop;
				}
			}
		}
		return copy;
	}
}
